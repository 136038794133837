import React, { useState, useEffect, useRef } from "react";
import { countries } from "../components/countries";
import { Text } from "../languages/Language";
import RegisterFormRequest from "../fetch/RegisterForm";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function RegisterModal({ showModal, setShowModal,children }) {
    const [shippingAddress, setShippingAddress] = useState(true);
    const formRef = useRef(null);
    const [responseText, setResponseText] = useState("");
    const [response, setResponse] = useState(false);

    const submitForm = () => {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        const formValues = Object.fromEntries(formData.entries());
        // Formun doğrulama kurallarına uygun olup olmadığını kontrol et
        if (formRef.current.checkValidity()) {
          RegisterFormRequest(formValues).then((res)=>{
            if(res.resCode === 200){
              setShowModal(false);
              setResponse(true);
              setResponseText(res.resMessage)
            }else{
              setResponse(true)
              setResponseText(res.resMessage)
            }
          });  // Fonksiyonu çağır
        } else {
          formRef.current.reportValidity(); // Eksik alan varsa uyarı göster
        }
      }
    };



    useEffect(()=>{
      if(showModal){
        setShippingAddress(true)
      }

    },[showModal])

    const [selectedFile1, setSelectedFile1] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [selectedFile3, setSelectedFile3] = useState(null);

    const handleFileChange1 = (e) => {
      setSelectedFile1(e.target.files[0]);
    };

    const handleFileChange2 = (e) => {
      setSelectedFile2(e.target.files[0]);
    };

    const handleFileChange3 = (e) => {
      setSelectedFile3(e.target.files[0]);
    };

    const handleUpload1 = () => {
      if (selectedFile1) {
        // Dosyayı yükleme işlemini burada yapabilirsin.
      }
    };

    const triggerFileInput1 = () => {
      document.getElementById('fileInput1').click();
    };

    const triggerFileInput2 = () => {
      document.getElementById('fileInput2').click();
    };

    const triggerFileInput3 = () => {
      document.getElementById('fileInput3').click();
    };


    const renderForm = (
      <>
            <button
                className="text-white  items-center pr-2
                justify-center pt-1
                text-base font-bold  hover:opacity-75"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {children ? children :
                    <Text tid="Register" />}
            </button>
            {showModal ? (
                <>

          <div
              className="text-slate-600  fade In justify-center items-center flex self-center fixed inset-0 z-50
              outline-none focus:outline-none">
              <div className="relative flex  mx-auto w-[36rem] max-h-[90vh]">
                  {/*content*/}
                  <div className="scale-90 pb-4  border-0 rounded-lg  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">


                      {/*header*/}
                      <div className="flex justify-between rounded-t items-center p-2 pl-8  mt-2 overflow-y-visible">

                          <h2 className="flex w-48 justify-start  font-bold text-xl"><Text tid="RegistrationForm"/></h2>
                          <div className="mr-6">
                          <button className="mb-2 mt-2 w-28 py-1 rounded-sm font-bold  shadow-sm border-2 border-gray-300 hover:text-slate-400 "
                                  onClick={() => setShowModal(false)}><Text tid="Cancel"/></button>
                          <button className="mb-2 mt-2 ml-2 text-center w-28 py-1 font-bold shadow-sm border-2 hover:text-company-primary-color-100 border-company-primary-color  rounded-sm bg-company-primary-color text-white"
                                  onClick={submitForm}><Text tid="Register"/></button>
                          </div>

                      </div>

               {/*body*/}

               <form ref={formRef} className="overflow-y-auto" autoComplete="off">
              <div className="grid grid-cols-1 gap-2  pl-8 pr-8 ">

                  <div className="p-5 my-2 bg-white rounded-md gap-2 shadow-md border border-gray-300 ">
                      <h2 className="font-bold py-1"><Text tid="UserInfo"/></h2>
                      <hr className="p-1"></hr>
                        <div className=" py-1">
                          <label className="mt-2"><Text tid="FirstName"/>*</label>
                          <input name="shopUserFirstname" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0" required/>
                        </div>
                        <div className=" py-1">
                          <label className="mt-2"><Text tid="LastName"/>*</label>
                          <input name="shopUserSurname" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0" required/>
                        </div>

                        <div className=" py-1">
                          <label className="mt-2"><Text tid="Email"/>*</label>
                          <input name="shopUserEmail" type="email" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0" autoComplete="off" required/>
                        </div>
                        <div className=" py-1">
                          <label className="mt-2"><Text tid="Phone"/>*</label>
                          <input name="shopUserPhone" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0" required/>
                        </div>
                  </div>

                  <div className="p-5 my-2 bg-white rounded-md shadow-md border border-gray-300">
                  <h2 className="font-bold py-1"><Text tid="FirmInfo"/></h2>
                  <hr className="p-1"></hr>
                    <div className="py-1">
                      <label className="mt-2 "><Text tid="FirmName"/>*</label>
                      <input name="customerName" type="text" className=" p-1 border bg-gray-50 border-gray-300 rounded w-full focus:ring-0" required />
                    </div>

                    <div className="grid grid-cols-2 gap-2 py-1">
                    <div>
                      <label className="mt-2"><Text tid="TaxID"/></label>
                      <input name="customerVATNr" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"/>
                    </div>
                    <div>
                      <label className="mt-2"><Text tid="TaxNumber"/></label>
                      <input name="customerTaxNr" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"/>
                    </div>
                    </div>
                  </div>

                  <div className="p-5 my-2 bg-white rounded-md shadow-md border border-gray-300">
                  <h2 className="font-bold py-1"><Text tid="Documents"/></h2>
                  <hr className="p-1"></hr>
                  <div className="flex flex-col justify-between gap-x-1">
                      <input type="file" id="fileInput1" name="fileInput1" onChange={handleFileChange1} className="hidden" />
                      <input type="file" id="fileInput2" name="fileInput2" onChange={handleFileChange2} className="hidden" />
                      <input type="file" id="fileInput3" name="fileInput3" onChange={handleFileChange3} className="hidden" />
                    <div className="flex flex-row justify-between text-center mt-4">  <div className="text-center justify-center"><Text tid="BusinessLicense"/></div> <div className="flex gap-x-1"><>{selectedFile1&&selectedFile1.name}</><button onClick={triggerFileInput1} type="button" className="w-24 bg-gray-50 border border-gray-300 text-gray-600 py-1 px-2 rounded hover:bg-gray-200" ><FontAwesomeIcon icon={faUpload} className="h-3 w-3 fill-white stroke-white"/></button> </div> </div>
                    <div className="flex flex-row justify-between text-center mt-4">  <div className="text-center justify-center"><Text tid="CommercialRegister"/></div> <div className="flex gap-x-1"><>{selectedFile2&&selectedFile2.name}</><button onClick={triggerFileInput2} type="button" className="w-24 bg-gray-50 border border-gray-300 text-gray-600 py-1 px-2 rounded hover:bg-gray-200" ><FontAwesomeIcon icon={faUpload} className="h-3 w-3 fill-white stroke-white"/></button> </div> </div>
                    <div className="flex flex-row justify-between text-center mt-4">  <div className="text-center justify-center"><Text tid="OtherDocuments"/></div> <div className="flex gap-x-1"><>{selectedFile3&&selectedFile3.name}</><button onClick={triggerFileInput3} type="button" className="w-24 bg-gray-50 border border-gray-300 text-gray-600 py-1 px-2 rounded hover:bg-gray-200" ><FontAwesomeIcon icon={faUpload} className="h-3 w-3 fill-white stroke-white"/></button> </div> </div>

                    </div>
                  </div>


                  <div className="p-5 my-2 bg-white rounded-md gap-2 shadow-md border border-gray-300">
                    <div className="flex flex-row justify-between">
                      <h2 className="font-bold py-1"><Text tid="FirmAddress"/></h2>
                      <div className="m-0">
                          <label className="flex items-center">
                          <input type="checkbox" checked={shippingAddress} className="mr-2 focus:ring-0" onChange={(e) => setShippingAddress(e.target.checked)} />
                          <Text tid="ShippingAddressSame"/>
                        </label>
                      </div>
                    </div>
                  <hr className="p-1"></hr>
                      <div className="gap-2">
                      <label className="mt-2"><Text tid="Address"/>*</label>
                      <input name="customerAddress" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0" required/>
                      </div>

                      <div className="grid grid-cols-4 gap-2 py-1">
                        <div className="col-span-1">
                          <label className="mt-2"><Text tid="PostalCode"/>*</label>
                          <input name="customerPostCode"
                            type="text"
                            className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"
                            required
                          />
                        </div>
                        <div className="col-span-3">
                          <label className="mt-2"><Text tid="City"/>*</label>
                          <input name="customerCity"
                            type="text"
                            className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"
                            required
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-2 py-1">

                      <div>
                      <label className="mt-2"><Text tid="Country"/>*</label>
                      <select name="customerCountry" required className="p-1 border bg-gray-50 border-gray-300 rounded w-full focus:ring-0">
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      </div>

                      <div>
                        <label className="mt-2"><Text tid="AdditionalInfo"/></label>
                        <input name="customerAdrInfo" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0" />
                      </div>

                      </div>
                </div>


                {!shippingAddress && (
                  <>
                   <div className="p-5 my-2 bg-white rounded-md gap-2 shadow-md border border-gray-300">
                   <h2 className="font-bold py-1"><Text tid="ShippingAddress"/></h2>
                   <hr className="p-1"></hr>
                    <div className="gap-2 py-1">
                      <label className="mt-2"><Text tid="Address"/>*</label>
                      <input name="shippingAddress"
                        type="text"
                        className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"
                        required={!shippingAddress}
                      />
                    </div>

                      <div className="grid grid-cols-4 gap-2 py-1">
                        <div className="col-span-1">
                          <label className="mt-2"><Text tid="PostalCode"/>*</label>
                          <input name="shippingPostCode"
                            type="text"
                            className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"
                            required={!shippingAddress}
                          />
                        </div>
                        <div className="col-span-3">
                          <label className="mt-2"><Text tid="City"/>*</label>
                          <input name="shippingCity"
                            type="text"
                            className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"
                            required={!shippingAddress}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-2 py-1">

                      <div>
                      <label className="mt-2"><Text tid="Country"/>*</label>
                      <select name="shippingCountry" required className="p-1 border bg-gray-50 border-gray-300 rounded w-full focus:ring-0">
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      </div>

                      <div>
                        <label className="mt-2"><Text tid="AdditionalInfo"/></label>
                        <input name="shippingAdrInfo" type="text" className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0"/>
                      </div>

                      </div>
                </div>

                  </>
                )}


                <div className="p-5 my-2 bg-white rounded-md gap-2 shadow-md border border-gray-300 ">
                    <h2 className="font-bold py-1"><Text tid="YourMessage"/></h2>
                    <hr className="p-1"></hr>
                      <div className=" py-1">
                        <textarea name="requestNote" type="text" rows="3" maxLength={256} className="w-full p-1 border bg-gray-50 border-gray-300 rounded focus:ring-0" required/>
                      </div>
                </div>





              </div>
            </form>

          </div>
                        </div>
                    </div>
                    <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );


    return (
        <div>
            {
                 renderForm
            }
            {response?
          <>
          <div
              className="justify-center items-center flex overflow-x-hidden
overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div
                  className="relative w-auto my-6 mx-auto max-w-3xl"
              >
                  {/*content*/}
                  <div
                      className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                      {/*header*/}
                      <div
                                className=" flex items-start text-center justify-center p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold text-center text-black"
                                >
                                  <Text tid="RegistrationForm"></Text>
                                </h3>

                            </div>
                      {/*body*/}
                      <div
                          className="min-w-[15rem] relative p-6 flex-auto"
                      >
                          <div
                              className="text-center text-black">
                              {responseText}
                              <div className='flex flex-row justify-center mt-6'>
                                  <button
                                  onClick={() => setResponse(false)}
                                      className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                      type="button"

                                  >
                                      <Text tid="Ok" />
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div
              className="opacity-50 fixed inset-0 z-40 bg-black"
          >
          </div>
      </>
        :null}
        </div>
    );
}

